import _taggedTemplateLiteralLoose from "@babel/runtime/helpers/esm/taggedTemplateLiteralLoose";
function _templateObject() {
    var data = _taggedTemplateLiteralLoose(["\n  html, body {\n    width: 100%;\n    margin: 0;\n  }\n\n  * {\n    font-family: 'Noto Sans JP', sans-serif, Cica, \u30E1\u30A4\u30EA\u30AA !important;\n  }\n\n  body {\n    background-color: rgb(", ");\n    color: rgb(", ");\n  }\n\n  a {\n    color: rgb(", ");\n  }\n\n  a.anchor.before {\n    box-shadow: none;\n    color: rgba(", ", 0.5);\n\n    :hover {\n      color: rgba(", ", 0.7);\n      text-decoration: underline;\n    }\n  }\n"]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
import { createGlobalStyle } from 'styled-components';
export var palette = {
    darkBase: '0,0,0',
    darkMain: '255,255,255',
    darkAccent: '0, 122, 204',
    lightBase: '255,255,255',
    lightMain: '0,0,0',
    lightAccent: '0, 122, 204'
};
export var GlobalStyles = createGlobalStyle(_templateObject(), function (props) {
    return props.theme.global.bg;
}, function (props) {
    return props.theme.global.color;
}, function (props) {
    return props.theme.global.link;
}, function (props) {
    return props.theme.global.color;
}, function (props) {
    return props.theme.global.color;
});
var baseTheme = {
    palette: palette
};
export var darkTheme = Object.assign({}, baseTheme, {
    global: {
        bg: palette.darkBase,
        color: palette.darkMain,
        link: palette.darkAccent
    }
});
export var lightTheme = Object.assign({}, baseTheme, {
    global: {
        bg: palette.lightBase,
        color: palette.lightMain,
        link: palette.lightAccent
    }
});
